.feis-logo {
  width: 136px;
  cursor: pointer;
}
.hr-org-body {
  border-bottom: 1.27532px solid #eaeaea;
}
.db-tab-container .MuiTabs-flexContainer {
  justify-content: flex-start;
}
.hr-org-title {
  border-bottom: 1.894px solid rgba(140, 174, 120, 0.12);
}
.hi-name {
  padding: 0 7%;
  color: #424242;
  font-size: 30px;
  margin-top: 2rem;
}
.db-tabs-div {
  padding: 0 7%;
}
.db-tabs-div .MuiButtonBase-root {
  padding: 0 !important;
  min-width: auto !important;
  margin-right: 30px;
  margin-top: 10px;
}
.db-tab {
  color: #424242 !important;
  opacity: 0.6 !important;
  font-size: 14px !important;
}
.db-tabs-div .Mui-selected {
  color: #68ad3f !important;
  opacity: 1 !important;
}
.db-body {
  background-color: #fafafa;
  padding: 0 7%;
  min-height: 90vh;
  padding-bottom: 100px;
}
.db-org-title {
  color: #424242;
  font-size: 22px;
  font-weight: 500;
}
.db-dropdown .dropdown-toggle::after {
  display: none;
}
.create-event-opt {
  cursor: pointer;
  height: 42px;
  font-size: 14px;
  color: #000000;
}
.db-tab-container .MuiTabPanel-root {
  padding: 0 !important;
}
.MuiPopover-paper {
  width: 185px !important;
}

.dashboard-box {
  margin: 10px 0px 30px 0;
  width: 100%;
  /* height: 90vh; */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px #f0f0f0;
  padding: 30px;
}

.admin-db-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 28px;
}

.admin-db-card,.selectable-admin-db-card {
  width: calc(49% - 12px);
  height: 120px;
  margin-bottom: 38px;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  box-shadow: 0px 2px 4px #68ad3f;
  border-radius: 4px;
  color: #68ad3f !important;
}
.admin-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.admincard-head {
  font-weight: 400;
  font-size: 20px;
  color: #68ad3f;
}

.admincard-descr {
  font-weight: 400;
  font-size: 16px;
  color: #787878;
}
.org-dropdown{
  position: absolute;
   border: 1px solid black;
    border-radius: 4px;
     background: white;
       height: 200px;
        overflow-y: scroll; 
        z-index: 1;
}
.org-options{
  padding-left: 4px;
}
.org-options:hover{
  background:blue;
  color:white;
  cursor:pointer

}

.dashboard-select,.dashboard-select-disabled {
    width:100%;
    height:32px;
  border-radius: 4px;
  border: 1px solid #797979;
  background: #fff;
  -webkit-appearance: none;
  background-image: url("../../Assets/selectArrow.png");
  background-size: 12px 6px !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%; 
 padding-right: 25px !important;

}
.dashboard-input,.dashboard-input-disabled{
  width:100%;
  height:32px;
  border-radius: 4px;
  border: 1px solid #797979;
}
.dashboard-input-disabled{
  pointer-events: none;
  opacity:0.7;
}
.dashboard-input:focus-visible{
  outline:none !important;
}
.dashboard-select-disabled{
    opacity:0.3 !important;
    pointer-events: none !important;
}
.selectable-admin-db-card{
  cursor:pointer;
}


@media only screen and (min-width: 0px) and (max-width: 480px) {
  .hi-name {
    padding: 0 5%;
  }
  .db-body {
    padding: 0 5%;
  }
  .db-tabs-div {
    padding: 0 5%;
  }
  .create-event {
    font-size: 12px;
    padding: 7px 9px;
  }
  .db-tabs-div .MuiTabs-indicator {
    bottom: 0px !important;
  }
  .feis-logo {
    width: auto;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .hi-name {
    padding: 0 5%;
  }
  .db-body {
    padding: 0 5%;
  }
  .db-tabs-div {
    padding: 0 5%;
  }
  .db-tabs-div .MuiTabs-indicator {
    bottom: 0px !important;
  }
  .admin-db-card,.selectable-admin-db-card {
    width: 100%;
  }
  .feis-logo {
    width: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .db-body,
  .db-tabs-div,
  .hi-name {
    padding: 0 7%;
  }
}

@media only screen and (max-width: 1024px) {

.competitorTable {
    width: 1100px !important;
  }
}