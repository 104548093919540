@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-size: 52px !important;
}
h2 {
  font-size: 40px !important;
}

h3 {
  font-size: 30px !important;
}
.subtitle-font {
  font-size: 21px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.702469);
}
.color-primary {
  color: #68ad3f;
}
.color-subtitle {
  color: #323232;
  font-size: 24px;
  font-weight: 400;
}
.font-opacity-2 {
  opacity: 0.2;
}
.no-pointer-events{
  pointer-events: none !important;
}

/* --------------------------------Reusable classes start--------------------------------- */
.border-btm {
  border-bottom: 1px solid #8cae781f;
  z-index: -1;
  position: absolute;
  width: 100%;
  left: 0px;
}
/* --------------------------------Reusable classes end--------------------------------- */
@media (min-width: 0px) and (max-width: 560px) {
  h1 {
    font-size: 24px !important;
  }
  h2 {
    font-size: 20px !important;
    font-weight: 500;
  }
}
