.heading {
    height: 82px;
    display: flex;
  }
  .trans-events {
    width: 100%;
    box-shadow: 0px 15px 42px rgba(0, 0, 0, 0.05);
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px 32px;
    margin-bottom: 32px;
    margin-top: 20px !important;
    text-align: left;
  
  }
  .web-trans-list {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .trans-event-title{
  font-size: 18px;
  color: #424242;
  }
  .trans-event-date{
  font-size: 14px;
  color: #797979;
  }
  .trans-event-amt{
  font-weight: 500;
  font-size: 16px;
  color: #68AD3F;
  }
  .trans-container{
    padding:0% 12%;
  }
  .event-img {
    height: 100px;
    width: 100px;
    padding: 11px;
    margin-left: -11px;
  }
  .event-mobile {
    display: none;
  }
  @media only screen and (min-width: 0px) and (max-width:480px) {
    .trans-events {
      padding: 5px 0px 5px 0px;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
    }
    .web-trans-list {
      display: none;
    }
    .trans-event-title{
      font-size: 14px;
      }
      .trans-event-date{
      font-size: 10px;
      }
      .trans-event-amt{
      font-size: 12px;
      }
      .trans-container{
        padding: 0% 6%;
      }
      .reg-close-trans{
        border-top: 1px solid #eaeaea;
        padding:5px 20px;
     }
     .event-img {
        height: 50px;
        width: 50px;
        padding: 2px;
      }
      .event-mobile {
        width: 100%;
        display: block;
      }
      .event-mobile-div {
        display: flex;
        align-items: center;
        padding: 0px 20px 0px 20px;
      }
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
  .trans-events {
    padding:5px 0px 5px 0px;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
  }
  .event-mobile {
    display: none;
  }
  .web-trans-list {
    display: none;
  }

  .trans-event-title{
    font-size: 14px;
    }
    .trans-event-date{
    font-size: 10px;
    }
    .trans-event-amt{
    font-size: 12px;
    }
    .trans-container{
      padding: 0% 6%;
    }
    .reg-close-trans{
      border-top: 1px solid #eaeaea;
      padding:5px 20px;
   }
   .event-img {
    height: 50px;
    width: 50px;
    padding: 2px;
  }

  .event-mobile-div {
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 20px;
  }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .trans-event-title{
      font-size: 14px;
      }
      .trans-event-date{
      font-size: 10px;
      }
      .trans-event-amt{
      font-size: 12px;
      }
      .trans-container{
        padding:0% 6%;
      }
  }
  