.create-event-btns {
    display: flex;
    align-items: center;
    width: 190px;
    height: 54.29px;
    box-shadow: 0px 15px 28px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #424242;
    background-color: #ffffff;
    padding: 14px;
    column-gap: 10px;
    margin-bottom: 16px;
    cursor: pointer
}

.next-btn,.next-btn-disabled {
    background: #68AD3F;
    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    padding: 9px 11px;
    border: 1px solid #68AD3F;
}

.next-btn-disabled {
    opacity: 0.3 !important;
    font-weight: 400;
    font-size: 15px;
}

.select-label {
    font-weight: 500;
    font-size: 14px;
}

.select-label-error {
    color: red;
    font-weight: 500;
    font-size: 14px;
}

.input-control:focus-within label {
    color: #68ad3f;
}

.input-control:focus-within select {
    color: #68ad3f !important;
}

.input-box-org-select-error {
    width: 248px;
    border: 1px solid red;
    color: red !important;
}

.input-text-area-modal-length {
    width: 248px;
    text-align: right;
    color: #424242;
}
.close-div{
width: 100%;
display: flex;
justify-content: flex-end;
padding: 0px 25px 0px 28px;
margin-top: 33px;
}
.main-div {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: auto;
  border-radius: 4px !important;
}
@media only screen and (min-width: 500px) and (max-width: 1900px) {
    .main-div {
      width: 500px;
    }
}