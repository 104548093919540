.card-events{
    width: 100%;
  height: 127px;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05);
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    margin-bottom: 32px;
    margin-top: 20px !important;
    cursor: pointer;
  }
  .card-events-web{
          display: flex;
          align-items: center;
          width: 100%;
  }
  .card-image{
      height: 80px;
      width: 80px;
  }
  .card-title{
      color: #68AD3F;
      font-size: 12px;
      font-weight: 500;
      }
      .card-event-name{
      color:#424242;
      font-size: 18px;
      }
      .card-date{
      color: #797979;
      font-size: 14px;
      }
      .card-mobile{
          display:none;
          width:100%;
      }
  @media only screen and (min-width: 0px) and (max-width: 480px) {
  
  .card-events {
      padding: 10px 0px 10px 0px;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
    }
    .card-events-web{
      display:none;
    }
    .card-mobile{
      display:block;
      width:100%;
    }
  
  .card-mobile-div {
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
  }
  .card-image{
      height: 75px;
      width: 75px;
  }
  .card-title{
      font-size: 10px;
  }
  .card-event-name{
      font-size: 12px;
  }
  .card-date{
      font-size: 12px;
  }
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
  .card-events {
          padding: 10px 0px 10px 0px;
          align-items: flex-start;
          flex-direction: column;
          height: 100%;
        } 
        .card-events-web{
          display:none;
        }
        .card-mobile{
          display: block;
          width:100%;
        }
  
      .card-mobile-div {
          display: flex;
          align-items: center;
          padding: 0px 10px 0px 10px;
      }
      .card-image{
          height: 75px;
          width: 75px;
      }
      .card-title{
          font-size: 13px;
      }
      .card-event-name{
          font-size: 15px;
      }
      .card-date{
          font-size: 12px;
      }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .card-title{
          font-size: 12px;
      }
      .card-event-name{
          font-size: 16px;
      }
      .card-date{
          font-size: 13px;
      }
  }
  @media only screen and (min-width: 1900px) {
    .card-title{
        font-size: 14px;
        }
        .card-event-name{
        font-size: 20px;
        }
        .card-date{
        font-size: 16px;
        }
  }
