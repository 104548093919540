.addCompetitor label {
  margin-top: 10px;
}
.accom-image{
  width:100px;
  height:100px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.MuiModal-root:focus {
  background-color: white !important;
}

.modal-org-title {
  font-size: 24px;
  font-weight: 400;
  color: #323232;
  margin: 30px 0;
}
.modal-org-subtitle {
  font-size: 16px;
  color: #323232;
}
.org-modal-div {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: auto;
  width: 421px;
  border-radius: 4px !important;
}
.add-comp-error{
  width: 245px;
}
.org-modal-div:focus-visible {
  outline: none !important;
}
.org-modal-url:focus-visible {
  outline: none !important;
}

.org-modal-url {
  display: flex;
  background-color: #ffffff;
  height: auto;
  width: 421px;
  border-radius: 4px !important;
}
.modal-select {
  padding-left: 8px;
  -webkit-appearance: none;
  background-image: url('../../../../Assets/selectArrow.png');
  background-size: 12px 7px !important;
  background-repeat: no-repeat;
  background-position: top 50% right 4%;
  outline: none;
  width: 248px;
  border: 1px solid #797979;
  border-radius: 4px;
  height: 40px;
}
.modal-input {
  padding-left: 8px;
  outline: none;
  width: 248px;
  border: 1px solid #797979;
  border-radius: 4px;
  height: 40px;
}
.custom-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: scroll !important;
  z-index: 1;
}
.custom-dropdown-options {
  padding-left: 8px;
}
.custom-dropdown-options:hover {
  color: white;
  background: #0d6efd;
  cursor: pointer;
}

.added-comp {
  padding: 20px 100px 50px 100px;
}
.split-input {
  width: 431px;
  height: 40px;
  border: 1px solid #797979;
  border-radius: 4px;
  padding-left: 10px;
  outline: none;
}
.merge-modal-div {
  width: auto !important;
  padding: 0px 40px 40px 40px !important;
}
.split-form-div {
  width: 421px;
}
.split-subtitle {
  padding: 0 50px;
  text-align: center;
}
.urlInput,
.urlInput:focus {
  -webkit-appearance: none;
  outline: none;
  border: none;
  width: 250px;
}
.copyUrlDiv {
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  padding: 10px;
  width: 300px;
  justify-content: space-between;
}
.urlInput:disabled {
  background-color: transparent;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
  .org-modal-div {
    width: 300px;
  }
  .added-comp {
    padding: 20px 0px 50px 0px;
  }
  .split-input {
    width: 250px;
  }
  .merge-modal-div {
    width: auto !important;
    padding: 0px 20px 40px 20px !important;
  }
  .modal-org-title {
    font-size: 18px;
  }
  .modal-org-subtitle {
    font-size: 12px;
  }
  .split-form-div {
    width: 260px;
  }
  .urlInput,
  .urlInput:focus {
    width: 200px;
  }

  .copyUrlDiv {
    width: 250px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .split-input {
    width: 250px;
  }
}
@media only screen and (min-width: 768px) {
  .split-modal-div {
    width: 500px !important;
  }
}

/* control */
.react-select > div  > div:nth-child(3){
  box-shadow: none !important;
  border-color: rgb(98, 101, 98) !important;
  width: 248px !important;
  background-image: url('../../../../Assets/selectArrow.png');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: top 50% right 4%;
}

/* Indicators Container */
.react-select > div  > div > div:nth-child(2) {
  display:none !important;
}

/* value Container */
.react-select > div  > div:nth-child(3) > div:nth-child(1) {
  max-width:235px !important;
}
.react-select > div  > div:nth-child(3) > div:nth-child(1)>div >div:nth-child(2):hover {
  background-color:hsl(0, 0%, 85%) !important;
  color:black;
}

.react-select > div  > div:nth-child(4){
overflow:scroll !important;
}

