.auth-title-org{
  color: #323232;
  font-size: 30px;
  text-align:center;
}
.label-head{
color:black;
  font-weight: 500;
  font-size: 12px;
  margin:10px auto 5px 5px;
}

.input-box{
  width: 248px;
  height: 40px;
  border: 1px solid #797979;
  border-radius: 4px;
  padding-left: 10px;
  outline:none;
}

.input-box-disabled::placeholder{
  background: transparent !important;
  color: #C9C9C9;
  font-size: 14px;
}
.input-box-disabled{
  background: transparent !important;
}

.select-box{
  width: 248px;
padding-left: 8px;
-webkit-appearance: none;
background-image: url("../../Assets/selectArrow.png");
background-size: 12px 7px !important;
background-repeat: no-repeat;
background-position: top 50% right 4%;
outline:none;
}

.checkbox-label{
  font-size: 14px;
  font-weight: 400;
  margin-left: 0;
  margin-top: 10px;
}

.text-button{
font-weight: 500;
font-size: 16px;
color: #68AD3F;
text-align: center;
cursor:pointer;
}

.auth-error{
  color:red;
  font-size: 12px;
}

::-webkit-input-placeholder {
   /* Edge */
  font-size: 14px;
  color: #EAEAEA;    
}

:-ms-input-placeholder { 
  /* Internet Explorer 10-11 */
  font-size: 14px;
  color: #EAEAEA;
  margin-left: 10px;
}

::placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  color: #EAEAEA;
  margin-left: 10px;
}

.org-account-outer{
  display: flex;
  justify-content:center;
  margin-top:50px;
}
.org-account-inner{
  width:500px;
}
.account-content{
font-weight: 400;
font-size: 16px;
}
.recover-pwd-msg{
  font-size: 15px;
  color: #323232;
}
.recover-alert{
  min-width:320px !important;
}
@media only screen and (min-width: 0px) and (max-width: 480px) {
  .account-content{
    font-size: 12px;
    }
    .recover-pwd-msg{
      font-size: 12px;
    }
    .auth-title-org-mob{
      font-size:24px;
    }
    .org-account-outer{
      padding: 0 5%;
      justify-content: flex-start;
      margin-top: 20px;
    }
    .org-account-inner{
      width:100%;
    }
    .recover-alert{
      min-width:260px !important;
    }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .account-content{
    font-size: 14px;
    }
    .org-account-outer{
      padding: 0 5%;
      justify-content: flex-start;
      margin-top: 20px;
    }
    .org-account-inner{
      width:100%;
    }
}
