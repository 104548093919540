.pageItem {
  width: 42px;
  height: 42px;
  border: 2px solid #68ad3f;
  color: #68ad3f;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}
.page-gap {
  gap: 18px;
}
.pageItemSelected {
  width: 42px;
  height: 42px;
  border: 2px solid #68ad3f;
  color: #ffffff;
  background-color: #68ad3f;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}
.prevNext {
  color: #68ad3f;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}
.prevNextDisabled {
  color: #68ad3f;
  font-weight: 500;
  font-size: 16px;
  opacity: 0.3;
}
.pageItem:hover {
  color: #ffffff;
  border: 2px solid #fbb040;
  background-color: #fbb040;
}
.mob {
  display: none;
}

@media only screen and (min-width: 0px) and (max-width: 800px) {
  .page-gap {
    gap: 10px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .pageItem {
    width: 20px;
    height: 20px;
    font-size: 10px;
    padding: 2px;
  }
  .pageItemSelected {
    width: 20px;
    height: 20px;
    font-size: 10px;
    padding: 2px;
  }

  .prevNext {
    font-size: 20px;
  }
  .prevNextDisabled {
    font-size: 20px;
  }
  .mob {
    display: block;
  }
  .web {
    display: none;
  }
}
