.manage-header{
    padding: 0 7%;
    background: #FFFFFF;
}
.manage-title-img{
    height:40px ;
    width:40px ;
    cursor:pointer
}
.org-title-name{
font-size: 24px;
font-weight: 400;
color: #424242;
}
.manage-title-type{
    font-weight: 500;
    font-size: 12px;
    color: #68AD3F;
}
.manage-title-date{
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}
.single-line-title{
    display: flex;
    padding: 27px 7%;
    column-gap: 16px;
}
.title-with-btn{
    display:flex;
    justify-content:space-between;
}
.title-btn-div{
    align-self:flex-end;
}
.invisible{
    visibility:hidden;
}
@media only screen and (min-width: 0px) and (max-width: 480px) {
    .org-title-name{
        font-size: 22px;
        }
        .db-org-title{
            font-size: 20px;
        }
        .manage-title-type{
            font-size: 10px;
        }
        
        .manage-title-date{
            font-size: 12px;
        }
        .manage-header{
            padding: 0 5%;
        }
        .single-line-title{
            padding: 15px 5%;
            flex-direction:column;
        }
        .title-with-btn{
            flex-direction:column;
        }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
    .org-title-name{
        font-size: 22px;
        }
        .db-org-title{
            font-size: 20px;
        }
        .manage-title-type{
            font-size: 10px;
        }
        .manage-title-date{
            font-size: 12px;
        }
        .manage-header{
            padding: 0 5%;
        }
        .single-line-title{
            padding: 15px 5%;
            flex-direction:column;
        }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .org-title-name{
        font-size: 22px;
        }
        .db-org-title{
            font-size: 20px;
        }
        .manage-header{
            padding: 0 7%;
        }
        .single-line-title{
            padding: 27px 7%;
        }
}
