
.stagesTableDiv tr> td{
  border-right: 1px solid #DCDCDC !important;
  border-collapse: collapse !important;
  padding:0 !important;
}
.stagesTableDiv td{
  min-width: 250px;
  max-width: 250px;
  height:50px;
  vertical-align: top !important;
}
.scheduleInput{
  margin-right: 10px;
  border-radius: 4px; 
  height:40px;
  width:180px;
}
.draggableItem {
height: 50px;
border-bottom: 1px solid #DCDCDC;
min-width: 250px;
max-width: 250px;
display: flex;
align-items: center;
}

.dragging{
border:1px solid  #DCDCDC;
}

[data-rbd-droppable-context-id] {
min-height: 200px;
overflow: scroll;
max-height: 400px;
}
.stickyFirst{
position: sticky;
top:0;
background-color: white;
border-right:1px solid #DCDCDC;
}

.stickySecond{
position: sticky;
top:50px;
background-color: white;
border-right:1px solid #DCDCDC;
}
.otherColumnCompName,.otherColumnCompCount,.eventsCompClass{
border-right:1px solid #DCDCDC;
height:100%;
display:flex;
align-items: center;
justify-content: center;
font-size: 12px;
}
.otherColumnCompName{
width:50px;
position: relative;
}
.eventsCompClass{
width:125px;
font-size: 14px;

}
.eventsCompCount{
width:100px;
height:100%;
display:flex;
align-items: center;
justify-content: center;
font-size: 14px;
}
.hoverOverlay{
position: absolute;
top: 0;
right: 0;
width:100%;
height: 100%;
font-size: 19px;
background-color: rgba(255, 0, 0, 0.5); /* Translucent red color */
color: white; /* Text color of the crossmark */
padding: 20% 40%;
display: none; /* Initially hidden */
}
.otherColumnCompName:hover .hoverOverlay{
display:block;
}
.breakTimeDiv:hover .hoverOverlay{
display:block;
padding:10% 40%;
}
.otherColumnCompCount{
width: 40px;
}
.otherColumnTime,.breakTimeDiv{
font-size: 12px;
display:flex;
align-items: center;
justify-content: center;
width:121px; 
flex-grow: 1;
height:100%;
position:relative;
}
.breakTimeDiv{
width:50%;

}


/* time picker css */
.rc-time-picker-panel {
z-index: 10070 !important;
}

.rc-time-picker-panel-select {
font-size: 14px !important;
max-height: 104px !important;
border:none !important;
width:34px !important

}
.rc-time-picker-panel-inner{
width:102px !important;
}

.rc-time-picker-input,.time-setter-input,.time-setter-modal-select {
width: 102px !important;
height:40px !important;
border:1px solid #797979 !important;
border-radius: 4px !important;
padding:4px;
font-size: 12px;
}

.rc-time-picker-panel-select li {
padding:4px !important;
height:25px !important
}
.rc-time-picker-input:focus-visible{
outline: none !important;
}
.rc-time-picker-clear{
display:none !important;
}
.rc-time-picker-input:focus-visible,.time-setter-input:focus-visible{
outline:none !important;
}
.time-setter-modal-select{
-webkit-appearance: none;
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAYCAYAAACMcW/9AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACnSURBVHgBzdfJDcAgDARAd04JlOASKYEEEn8SQjh8rWSJB2JHiA8AV8I56Z4I9ml6yiI/BsEuCG9PgVd1Bh9Y/LCkHlQbix1HhYbOBi0s/hji6EZJ7HS3BXa5UxO73aWBZeuQxLKfLYEVuwDOg8WfFEeBOJKjSA25U6iOXCk2Q84AzJGjWBfIHaw6kjKDNUNSRrDmSEoP6wZJaWHdISnly0Df3ACMOQATckHecQwCXQAAAABJRU5ErkJggg==);
background-repeat: no-repeat;
background-size: 10%;
background-position: top 50% right 8%;
}
