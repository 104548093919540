/* The radioContainer */
.radioContainer {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .radioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .radioCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border-radius: 50%;
    border:2px solid #68AD3F;
  }
  
  /* On mouse-over, add a grey background color */
  .radioContainer:hover input ~ .radioCheckmark {
    cursor:pointer;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radioCheckmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radioContainer input:checked ~ .radioCheckmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radioContainer .radioCheckmark:after {
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #68AD3F;
  }
  