
.congratulations {
    background: white;
    padding: 5vh 10vh;
    border-radius: 4px;
    box-shadow: 0px 11px 46px rgba(0, 0, 0, 0.15);
    display: inline-block;
    margin: 0 auto;
    text-align: center; 
    color:#323232;
  }
  @media only screen and (min-width: 0px) and (max-width: 330px) {
    .congratulations{
        padding: 0vh !important;
    }
    }
    
    /*  <---------------------------- media query for mobile devices -------------------------------------> */
    @media only screen and (min-width: 331px) and (max-width: 650px) {
      .congratulations{
          padding: 7vh;
      }
    }
     @media only screen and (min-width: 1500px) and (max-width: 1800px) {
      .congratulations{
          width: 540px;     
      }
      
     }
     @media only screen and (min-width: 1801px) and (max-width: 24000px) {
      .congratulations{
        width: 34rem;
        padding: 60px;
      }
      
     }