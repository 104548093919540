.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-loader i {
  font-size: 70px;
  color: rgb(255, 0, 0);
}
