.toggle-switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
  }
  
  .toggle-switch input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #EAEAEA;
    transition: 0.4s;
    border-radius: 24px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 3px;
    background-color: #797979;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input[type="checkbox"]:checked + .slider:before {
    transform: translateX(33px);
    background-color: green;
  }
  