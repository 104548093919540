.modal-content {
  height: 374px;
  border: none;
}
.modal-header {
  border-bottom: none !important;
}
.modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  height: 200px;
}
.modal-footer {
  justify-content: center !important;
  border: none !important;
  padding: 0px 10px 0px 10px;
  height: 110px;
}
