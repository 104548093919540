.back-nav{
    font-weight: 500;
    font-size: 16px;
    color: #68AD3F;
    padding-left:5px;
    }
    
@media only screen and (min-width: 0px) and (max-width: 480px) {
    .back-nav{
        font-size: 14px;
        }
}

@media only screen and (min-width: 481px) and (max-width: 967px) {
    .back-nav{
        font-size: 14px;
        }        
}