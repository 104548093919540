.searchDiv {
    background-color: white;
    border: 1px solid #797979;
    border-radius: 5px;
    color:#797979;
  }

.search,
.search:focus {
    width:100%;
    height:30px;
  border-width: 0px;
  border-radius: 5px;
  outline: 0;
  -webkit-appearance: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}