.prev-page-title{
    padding: 0 7%;
    margin-top:1rem;
    display:flex;
}
.prev-evt-head{
font-weight: 400;
font-size: 24px;
color: #424242;
margin-left: 3rem;
}
.tabs-prev{
    margin-bottom:1px solid gray
}
.db-tab-container .MuiTabs-flexContainer{
justify-content: flex-start;
}
.db-tab-container .MuiTabs-indicator{
    height:3px;
    border-radius:4px;
}
@media only screen and (min-width: 0px) and (max-width: 480px) {
    .prev-page-title{
        padding: 0 5%;
        margin-top:1rem;
        flex-direction:column;
    }
    .prev-evt-head{
        margin-left: 0;
    }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
    .prev-page-title{
        padding: 0 5%;
        margin-top:1rem;
        flex-direction:column;
    }
    .prev-evt-head{
        margin-left: 0;
    }
}