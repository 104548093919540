#input input,select {
  height: 43px;
  border: 1px solid #797979;
  border-radius: 4px;
  margin-bottom: 2px;
  outline: none;
  padding: 0px 20px 0 9px;
  color: #000000;
}

select {
  color: black;
  padding: 0px 9px;
  word-wrap: normal;
  text-overflow: ellipsis !important;
}

#input label {
  font-weight: 500;
  padding: 5px 0px 5px 0px;
  color: #000000;
  font-size: 12px;
}

button,select {
  text-transform: none;
  padding: 0px 19px;
}

button,input,optgroup,select,textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}


#input select option {
  height: 46px;
  border-bottom: 1px solid black;
}

#addDancerinput, #editDancerinput {
    padding: 0px 12px !important;
}

.formikSelect {
    -webkit-appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAYCAYAAACMcW/9AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACnSURBVHgBzdfJDcAgDARAd04JlOASKYEEEn8SQjh8rWSJB2JHiA8AV8I56Z4I9ml6yiI/BsEuCG9PgVd1Bh9Y/LCkHlQbix1HhYbOBi0s/hji6EZJ7HS3BXa5UxO73aWBZeuQxLKfLYEVuwDOg8WfFEeBOJKjSA25U6iOXCk2Q84AzJGjWBfIHaw6kjKDNUNSRrDmSEoP6wZJaWHdISnly0Df3ACMOQATckHecQwCXQAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-size: 4%;
    background-position: top 50% right 4%;
    width: 248px;
    height: 42px;
}

.custom-select {
  position: relative;
  font-family: "Arial",  sans-serif ;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) and (max-width: 1400px) {
  #input label {
    font-weight: 500;
    padding: 5px 0px 5px 0px;
    color: #000000;
    font-size: 12px;
  }
}