.collaborator-modal {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: auto;
  width: 500px;
  border-radius: 4px !important;
}
.collaborator-modal:focus-visible {
  outline: none !important;
}
.remove-margin button {
  margin: 0 !important;
}

.modal-box{
    max-height: 100vh;
    padding: 5px 100vw;
    overflow: scroll;
}
.modal-box:focus-visible {
    outline:none !important;
}
.mui-box:focus-visible {
    outline:none !important;
}
.acc-card{
  width: 311px;
height: 99.736px;
border-radius: 4px;
background: var(--neutral-white, #FFF);
box-shadow: 0px 15px 28px 0px rgba(0, 0, 0, 0.05);
display:flex;
}
.acc-collab-del svg{
  color: #da1414;
  opacity: 0.6;
  cursor: pointer;
  font-size: 18px;
  margin-right: 4px;
}
.acc-collab-name {
  font-weight: 400;
  font-size: 16px;
  color: #424242;
}
.acc-collab-location{
  color:  #68AD3F;
font-size: 12px;
font-weight: 500;
opacity: 0.6000000238418579;
}
.acc-collab-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
  padding: 10px;
  width:100%;
}
@media only screen and (min-width: 0px) and (max-width: 480px) {
  .collaborator-modal {
    width: 300px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .collaborator-modal {
    width: 450px;
  }
}
