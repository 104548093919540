.errorui {
  justify-content: center;
  align-items: center;
  margin-top: 16%;
}
.errorui h3 {
  font-size: 85px;
}
.errorui h5 {
  font-size: 30px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.errorui button {
  border-radius: 4px;
  font-size: 22px;
  padding: 7px 17px;
  background-color: #68ad3f;
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border: 2px solid #68ad3f;
  margin: auto;
}
.errorui button:hover {
  background: #fbb040;
  color: #ffffff;
  border: 2px solid #fbb040;
}
.errornum h1 {
  position: absolute;
  font-size: 340px !important;
  opacity: 0.23;
  margin: unset;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.errorContent {
  margin: unset;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

@media (max-width: 601px) and (max-width: 700px) {
  .errornum h1 {
    position: absolute;
    font-size: 50vw !important;
    opacity: 0.23;
    margin: unset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 600px) {
  .errorui h5 {
    font-size: 15px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }
  .errorui button {
    border-radius: 4px;
    font-size: 15px;
    padding: 7px 17px;
    background-color: #68ad3f;
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    border: 2px solid #68ad3f;
    margin: auto;
  }
}
